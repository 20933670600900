import produce from 'immer'
import { useCallback, useState } from 'react'
import set from 'lodash-es/set'
import get from 'lodash-es/get'

type WsStateType = {
  requests: Record<string, ws.Request>
}

const defaultState = {
  requests: {},
}

const WsModel = () => {
  const [ws, wsReplace] = useState<WsStateType>(defaultState)

  const requestAdd = useCallback((request: Omit<ws.Request, 'end'>) => {
    wsReplace(
      produce((draft: any) => {
        draft.requests[request.id] = request
        return draft
      })
    )
  }, [])

  const wsSet = useCallback(
    (path: string, value: any) => wsReplace(produce<WsStateType>((draft) => (typeof value === 'function' ? value(get(draft, path)) : set(draft, path, value)))),
    []
  )

  const wsSetMul = useCallback(
    (paths: string[], values: ValueOrFunc[]) =>
      wsReplace(
        produce((draft) => {
          paths.forEach((path, i) => {
            set(draft, path, typeof values[i] === 'function' ? values[i](get(draft, path)) : values[i])
          })
        })
      ),
    []
  )

  return { wsSet, requestAdd, ws, wsSetMul }
}

export default WsModel
