import React from 'react'

import ws from '@/models/ws'
import view from '@/models/view'

import { UmiContext } from './helpers/constant'
import Dispatcher from './helpers/dispatcher'
import Executor from './helpers/executor'

export const models = {
  ws,
  view,
}

const dispatcher = new Dispatcher!()
const Exe = Executor!

const Provider = ({ children }: { children: React.ReactNode }) => {
  return (
    <UmiContext.Provider value={dispatcher}>
      {Object.entries(models).map(([namespace, hook]) => (
        <Exe
          key={namespace}
          namespace={namespace}
          hook={hook}
          onUpdate={(val: any) => {
            dispatcher.data[namespace] = val
            dispatcher.update(namespace)
          }}
        />
      ))}
      {children}
    </UmiContext.Provider>
  )
}

export default Provider
