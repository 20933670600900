import React, { useEffect } from 'react'
import type { ReactElement, ReactNode } from 'react'
import dynamic from 'next/dynamic'
import type { AppProps } from 'next/app'
import '@/style/tailwind.css'
import '@/style/global.css'
import 'antd/dist/reset.css'
import Head from '@/components/Header'
import ModelProvider from 'plugin-model/Provider'
import ThemeLayout from '@/components/ThemeLayout'
import 'braft-editor/dist/index.css'
import 'braft-editor/dist/output.css'
import 'animate.css'

import type { NextPage } from 'next'
import { StyleProvider } from '@ant-design/cssinjs'
import { SWRConfig } from 'swr'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/locale/zh_CN'
import BrowserVersionCheck from '@/components/BrowserVersionCheck'

declare let WeixinJSBridge: any
declare let document: any

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}
type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}
const Layout = dynamic(() => import('@/components/Layout'), { ssr: false })

const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  const getLayout = Component.getLayout ?? ((page) => page)

  useEffect(() => {
    const packageJson = require('../package.json')
    const version = packageJson.version
    const currentVersion = localStorage.getItem('version')
    if (currentVersion) {
      if (version > currentVersion) {
        localStorage.setItem('version', version)
        localStorage.removeItem('timer-left')
        localStorage.removeItem('timer-top')
        localStorage.setItem('scaleLevel', '100')
        localStorage.removeItem('qrcode-left')
        localStorage.removeItem('qrcode-top')
        localStorage.setItem('qrcodeScaleLevel', '100')
      }
    } else {
      localStorage.setItem('version', version)
    }
  }, [])

  useEffect(() => {
    function handleFontSize() {
      // 设置网页字体为默认大小
      WeixinJSBridge.invoke('setFontSizeCallback', { fontSize: 0 })
      // 重写设置网页字体大小的事件
      WeixinJSBridge.on('menu:setfont', function () {
        WeixinJSBridge.invoke('setFontSizeCallback', { fontSize: 0 })
      })
    }
    if (typeof WeixinJSBridge === 'object' && typeof WeixinJSBridge.invoke === 'function') {
      handleFontSize()
    } else if (document.addEventListener) {
      document.addEventListener('WeixinJSBridgeReady', handleFontSize, false)
    } else if (document.attachEvent) {
      document.attachEvent('WeixinJSBridgeReady', handleFontSize)
      document.attachEvent('onWeixinJSBridgeReady', handleFontSize)
    }
  }, [])

  return (
    <ModelProvider>
      <ConfigProvider locale={zhCN}>
        <ThemeLayout>
          <SWRConfig value={{ revalidateOnFocus: false, refreshInterval: 900000 }}>
            <StyleProvider hashPriority="high">
              <Head component={Component} />
              <Layout need_login={pageProps.need_login} hide_menu={pageProps.hide_menu}>
                <BrowserVersionCheck>{getLayout(<Component {...pageProps} />)}</BrowserVersionCheck>
              </Layout>
            </StyleProvider>
          </SWRConfig>
        </ThemeLayout>
      </ConfigProvider>
    </ModelProvider>
  )
}

export default MyApp
