import React from 'react'
import Image from 'next/image'
import { isWechat } from '@/utils'

const UpgradeBrowser = () => {
  return (
    <div className="fixed inset-x-0 top-0 z-50 flex h-full flex-col items-center justify-center gap-6 bg-white px-4 text-center">
      <Image src="/lingti-gd-logo.png" className="mb-6" alt="" width={80} height={80} />
      <h1>请升级您的浏览器</h1>
      <div>您正在使用的浏览器版本过低，可能无法正常显示本页面的内容。请升级到最新版本的浏览器。</div>
      <a href="https://www.microsoft.com/zh-cn/edge/download">Edge浏览器</a>
    </div>
  )
}

const BrowserCheck = ({ children }) => {
  const getBrowserInfo = () => {
    const userAgent = navigator.userAgent
    let temp
    const browserMatch = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
    if (/trident/i.test(browserMatch[1])) {
      temp = /\brv[ :]+(\d+)/g.exec(userAgent) || []
      return { name: 'IE', version: temp[1] || '' }
    }
    if (browserMatch[1] === 'Chrome') {
      temp = userAgent.match(/\b(OPR|Edg)\/(\d+)/)
      if (temp !== null) {
        return { name: temp[1].replace('OPR', 'Opera').replace('Edg', 'Edge'), version: temp[2] }
      }
    }
    const matchResult = browserMatch[2] ? [browserMatch[1], browserMatch[2]] : [navigator.appName, navigator.appVersion, '-?']

    return { name: matchResult[0], version: matchResult[1] }
  }

  const isBrowserSupported = () => {
    const { name, version } = getBrowserInfo()
    const versionNumber = parseInt(version, 10)
    if (isWechat()) {
      return true
    }
    switch (name) {
      case 'Chrome':
        return versionNumber >= 60
      case 'Firefox':
        return versionNumber >= 54
      case 'Safari':
        return versionNumber >= 10
      case 'Edge':
        return versionNumber >= 15
      case 'IE':
        return versionNumber >= 11
      default:
        return true
    }
  }

  if (!isBrowserSupported()) {
    return <UpgradeBrowser />
  }

  return <>{children}</>
}

export default BrowserCheck
