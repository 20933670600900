import produce from 'immer'
import localStorage from 'localStorage'
import _ from 'lodash'
import { useCallback, useState } from 'react'

type ViewType = {
  isCreatingRound: boolean
  fullScreen: boolean
  rows: number
  presentation_rows: number
  mode: string
  presentation_mode: string
  display: string
  presentation_display: string
  gd_pre_auto_play_speed: number
}

const defaultState = {
  isCreatingRound: false,
  fullScreen: true,
  presentation_rows: +localStorage.getItem('presentation_rows') || 1,
  rows: +localStorage.getItem('rows') || 1,
  presentation_mode: localStorage.getItem('presentation_mode') ?? 'wide',
  mode: localStorage.getItem('mode') ?? 'wide',
  presentation_display: localStorage.getItem('presentation_display') ?? 'detail',
  display: localStorage.getItem('display') ?? 'detail',
  gd_pre_auto_play_speed: +localStorage.getItem('gd_pre_auto_play_speed') ?? 0,
}

const ViewModel = () => {
  const [view, viewReplace] = useState<ViewType>(defaultState)
  const viewSet = useCallback(
    (path: string, value: ValueOrFunc) => viewReplace(produce<ViewType>((draft) => _.set(draft, path, typeof value === 'function' ? value(_.get(draft, path)) : value))),
    []
  )
  return { view, viewReplace, viewSet }
}

export default ViewModel
