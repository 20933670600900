import dayjs from 'dayjs'

export function getDay(time) {
  return dayjs(time).format('YYYY-MM-DD')
}

export const formattedTime = (v) => dayjs(v).format('YYYY-MM-DD HH:mm:ss')

export function getRoot() {
  const { protocol, host } = global.location
  return `${protocol}//${host}`
}

export const isPhone = (s) => {
  return /^1[0-9]{10}$/.test(s)
}

export const isEmail = (s) => {
  return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(s)
}

export function isMobile() {
  if (typeof window !== `undefined`) {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  }
  return null
}

export function isAndroid() {
  if (typeof window !== `undefined`) {
    return /Android/i.test(navigator.userAgent)
  }
  return null
}

export function isIPhone() {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent)
}

export function isWechat() {
  if (typeof window !== `undefined`) {
    return /micromessenger/.test(navigator.userAgent.toLowerCase())
  }
  return null
}

// 判断ios app内置浏览器(非Safari)
export function isWkWebview() {
  if (typeof window !== `undefined`) {
    if (navigator.platform.startsWith('iP') && !/safari/.test(navigator.userAgent.toLowerCase())) {
      return true
    }
  }
  return false
}

export function isWindows() {
  return /windows|win32/i.test(navigator.userAgent)
}

export function isIE() {
  return /Edge|MSIE|trident/i.test(navigator.userAgent)
}

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent.toLowerCase())
}

export function isAgent() {}
