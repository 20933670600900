import { ConfigProvider } from 'antd'
import type { NextPage } from 'next'
import React from 'react'

type PropTypes = {
  children: React.ReactNode
}

const ThemeLayout: NextPage<PropTypes> = ({ children }) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#b69067',
          colorLink: '#b69067',
          colorBgBase: '#fff',
          colorLinkActive: '#b69067',
        },
      }}
    >
      {children}
    </ConfigProvider>
  )
}

export default ThemeLayout
